import React, { useState } from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaCopy } from 'react-icons/fa';

const Contact = () => {
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [messageStatus, setMessageStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value.trimStart() });
    };

    const validateInputs = () => {
        const nameRegex = /^[A-Za-z\s]{3,50}$/;
        const phoneRegex = /^\+?[0-9]{10,15}$/;
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        
        if (!nameRegex.test(formData.name)) return 'Invalid name format';
        if (!phoneRegex.test(formData.phone)) return 'Invalid phone number';
        if (formData.email && !emailRegex.test(formData.email)) return 'Invalid email address';
        if (formData.message.length < 5) return 'Message should be at least 5 characters long';
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const error = validateInputs();
        if (error) {
            setMessageStatus({ type: 'error', text: error });
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await fetch('https://formspree.io/f/mbldabge', {
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body: JSON.stringify(formData),
            });
            
            if (response.ok) {
                setFormData({ name: '', email: '', phone: '', message: '' });
                setMessageStatus({ type: 'success', text: 'Message sent successfully!' });
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessageStatus({ type: 'error', text: 'Failed to send message, please try again later.' });
        } finally {
            setIsSubmitting(false);
            setTimeout(() => setMessageStatus(null), 5000);
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => setCopiedText(text))
            .catch(err => console.error('Could not copy text: ', err));
    };

    return (
        <div>
            <section id="contact-us" className="contact-us-section">
                <div className="contact-us-container">
                    <h2>Contact Us</h2>
                    <p>We're here to help you. Get in touch with us through any of the methods below.</p>
                    <div className="contact-details">
                        <div className="contact-card">
                            <FaCopy className="copy-icon" onClick={() => handleCopy('+2348183310100')} />
                            {copiedText === '+2348183310100' && <span className="copied-text">Copied!</span>}
                            <a href="tel:+2348183310100">
                                <FaPhoneAlt className="contact-icon" />
                                <h3>Phone</h3>
                                <b>(234) 8183310100</b> <br /><br />
                                <p>
                                    Uninterrupted customer support, available around the clock.</p>
                            </a> 
                        </div>
                        <div className="contact-card">
                            <FaCopy className="copy-icon" onClick={() => handleCopy('info@oakhealthhospital.com')} />
                            {copiedText === 'info@oakhealthhospital.com' && <span className="copied-text">Copied!</span>}
                            <a href="mailto:info@oakhealthhospital.com">
                                <FaEnvelope className="contact-icon" />
                                <h3>Email</h3>
                                <b>info@oakhealthhospital.com</b> <br /><br />
                                <p>Leave us an email and we will respond as quickly as possible</p>
                            </a>
                        </div>
                        <div className="contact-card">
                            <FaCopy className="copy-icon" onClick={() => handleCopy('10 Irewunmi street, off ifesowapo street, Igbogbo road, Ikorodu, Lagos')} />
                            {copiedText === '10 Irewunmi street, off ifesowapo street, Igbogbo road, Ikorodu, Lagos' && <span className="copied-text">Copied!</span>}
                            <a href="https://www.google.com/maps/search/?api=1&query=10+Irewunmi+street,+off+ifesowapo+street,+Igbogbo+road,+Ikorodu,+Lagos" target="_blank" rel="noopener noreferrer">
                                <FaMapMarkerAlt className="contact-icon" />
                                <h3>Address</h3>
                                <p>10 Irewunmi street, off ifesowapo street, Igbogbo road, Ikorodu, Lagos</p>
                            </a>
                        </div>
                    </div>
                    <br />
                    
                    <hr />
                    <br /><br /><br />
                    <h1 id='booking-section'>Reach us here</h1> <br />
                    <form className="contact-form" onSubmit={handleSubmit} noValidate>
                        <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                        <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
                        <input type="tel" name="phone" placeholder="Your Phone Number" value={formData.phone} onChange={handleChange} required />
                        <textarea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange} required></textarea>
                        <button type="submit" disabled={isSubmitting}>{isSubmitting ? 'Sending...' : 'Send Message'}</button>
                    </form>
                    {messageStatus && <div className={`message ${messageStatus.type}`}>{messageStatus.text}</div>}
                </div>
            </section>
        </div>
    );
}

export default Contact;
