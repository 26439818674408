import React, { useState } from 'react';
import { 
    FaStethoscope, FaUserMd, FaHospital, FaAmbulance, FaHeartbeat, FaSyringe, FaXRay, FaBriefcaseMedical, FaPills, FaProcedures, FaClinicMedical, FaWheelchair, FaTooth, FaEye, FaBaby, FaBrain, FaBone, FaFlask,
    FaLungs, FaMicroscope, FaPrescriptionBottleAlt, FaThermometerHalf, FaUserNurse, FaVirus, FaTeeth, FaHandHoldingMedical, FaRadiation, FaUserShield, FaMedkit, FaVial,  
    FaDna, FaShieldAlt 
} from 'react-icons/fa';
import { GiScalpelStrike } from "react-icons/gi";


const Services = () => {
    const [showMore, setShowMore] = useState(false);

    const services = [
        { icon: <FaStethoscope />, name: 'General Medicine' },
        { icon: <FaPills />, name: 'Pharmacy' }, 
        { icon: <FaHospital />, name: 'In-patient Services' },
        { icon: <FaProcedures />, name: 'MIS and Outpatient Surgery' },
        { icon: <FaSyringe />, name: 'Vaccinations and Immunization' },
        { icon: <FaFlask />, name: 'Laboratory Services' },
        { icon: <FaThermometerHalf />, name: 'Primary Care' },
        { icon: <FaAmbulance />, name: 'Emergency Care' },


        // { icon: <FaHandHoldingMedical />, name: 'Health Checkups' },
        // { icon: <FaProcedures />, name: 'Surgery' },
        // { icon: <FaClinicMedical />, name: 'Clinics' },
        // { icon: <FaWheelchair />, name: 'Rehabilitation' },
        // { icon: <FaTeeth />, name: 'Orthodontics' },
        // { icon: <FaEye />, name: 'Ophthalmology' },
        // { icon: <FaBaby />, name: 'Pediatrics' },
        // { icon: <FaBone />, name: 'Orthopedics' },
        // { icon: <FaLungs />, name: 'Pulmonology' },
        // { icon: <FaPrescriptionBottleAlt />, name: 'Prescription Services' },
        // { icon: <FaUserNurse />, name: 'Nursing Services' },
        // { icon: <FaVirus />, name: 'Disease Management' },
        // { icon: <FaRadiation />, name: 'Cancer Care' },
        // { icon: <FaUserShield />, name: 'Immunotherapy' },
        // { icon: <FaVial />, name: 'Blood Tests' },
        // { icon: <FaDna />, name: 'Genetic Testing' },
    ]; 

    const displayedServices = showMore ? services : services.slice(0, 8);

    return (
        <div>
            <section id="services" className="services-section">
               <center> <h2>Our Services</h2></center> <br />
                <div className="services-container">
                    {displayedServices.map((service, index) => (
                        <div className="service-card" key={index}>
                            <div className="service-icon">{service.icon}</div>
                            <h3>{service.name}</h3>
                        </div>
                    ))}
                </div>
                {/* <button className="toggle-button" onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'View less' : 'See more'}
                </button> */}
            </section>
        </div>
    );
};

export default Services;
