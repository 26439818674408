import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import logo from '../logo2.png';


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <img className="logo" src={logo} alt="Logo" />
          <p className="">Our Motto: Health care with a difference.</p> <br />
          <p className='abou-word'>Address: 10 Irewunmi street, off ifesowapo street, Igbogbo road, Ikorodu, Lagos State, Nigeria</p>

        </div>
        <div className="footer-section">
          <br /><br />
          <br /><br />
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h4>Reach Us</h4>
          <div className="kkkk">
            <p><span>Email: </span><a href="mailto:info@oakhealthhospital.com">info@oakhealthhospital.com</a></p>
            <p><span>Phone: </span> <a href="tel:08183310100">(234) 8183310100</a></p>
          </div>
          <br />
<br />
          <div className="social-icons">
            <a href="#"><FaFacebookF /></a>
            <a href="#"><FaTwitter /></a>
            <a href="#"><FaInstagram /></a>
            <a href="#"><FaLinkedinIn /></a>
          </div>
          <br /><br />
          <p>Registered Number: 475451</p>

        </div>
      </div>
      <div className="footer-bottom">
        <p className='footer-brand'>&copy; {currentYear} Oak Health hospital. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
